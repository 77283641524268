import React from 'react';
import Eye from '../assets/eye.png';
import EyeOff from '../assets/eye-off.png';
import styled from 'styled-components';

type FilterMembersButtonProps = {
  on: boolean;
  onClick(): void;
  size: number;
  style?: React.CSSProperties;
};

const Icon = styled.img<{ size: number }>(
  ({ size }) => `
  width: ${size}px;
  height: ${size}px;
`
);

const StyledButton = styled.button<{ size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size + 10}px;
  height: ${({ size }) => size + 10}px;
  border-radius: ${({ theme }) => theme.radius.m}px;
  border: none;
  background-color: transparent;
  transition: background-color 0.2s;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bg.button}66;
  }
`;

export const FilterMembersButton: React.FC<FilterMembersButtonProps> = ({
  on,
  onClick,
  size,
  style,
}) => (
  <StyledButton onClick={onClick} size={size} style={style} title="Toggle inactive members">
    <Icon src={on ? Eye : EyeOff} size={size} />
  </StyledButton>
);
