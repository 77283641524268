const wowColors = {
  Alliance: '#2285E0',
  Horde: '#FF0000',
  Druid: '#FF7D0A',
  Hunter: '#A9D271',
  Mage: '#40C7EB',
  Paladin: '#F58CBA',
  Priest: '#FFFFFF',
  Rogue: '#FFF569',
  Shaman: '#0070DE',
  Warlock: '#8787ED',
  Warrior: '#C79C6E',
  DeathKnight: '#C41F3B',
  DemonHunter: '#A330C9',
  Monk: '#00FF96',
  Priority: '#00ff00',
  Upgrade: '#ff8000',
  Offspec: '#00b0b3',
  PvP: '#b30012',
  Pass: '#e1e6D8',
  Disenchant: '#e1e6D8',
  Banking: '#e1e6D8',
  'Needs Processing': '#e1e6D8',
  'Round Robin': '#e1e6D8',
};

const fontSizes = {
  xs: 10,
  s: 14,
  m: 16,
  ml: 18,
  l: 24,
  xl: 32,
};

type FontSize = typeof fontSizes;
type FontSizeKey = keyof FontSize;

const fonts = {
  primary: 'Open Sans, sans-serif',
};

export const getFontValue = (
  size: FontSizeKey,
  weight: number = 400,
  font: string = fonts.primary
) => ({ theme }: any) => ({
  fontSize: fontSizes[size],
  fontFamily: font,
  fontWeight: weight,
});

export const themeConfig = {
  wowColors,
  colors: {
    lime: '#e1e6D8',
    lightGrey: '#ccc',
    darkGrey: '#222',
    boxSeparator: '#141D20',
    bg: {
      primary: '#1C1C1C',
      secondary: '#303030',
      navItem: '#121517',
      activeNavItem: '#16191B',
      search: '#121517',
      searchBorder: '#1B242B',
      searchBorderActive: '#32424e',
      button: '#1c2023',
    },
    text: {
      primary: '#fff',
      secondary: '#D0D2D6',
      tertiary: '#888f94',
      nav: '#A4A0A0',
      subtle: '#ddd',
      placeholder: '#959595',
    },
    table: {
      headerBg: '#1B242B',
      headerText: '#BDBDBD',
      text: '#DBDBDB',
      separator: '#454E5F',
    },
  },
  gradient: {
    body:
      'linear-gradient(0deg, #0C0C0C 0%, rgba(40, 56, 67, 0.2) 62.5%), #0C0C0C',
    nav: 'linear-gradient(180deg, #10181C 0%, rgba(17, 17, 17, 0.1) 100%)',
    box: 'linear-gradient(180deg, #121D20 0%, #161414 100%)',
  },
  shadow: {
    nav: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    box: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  spacing: {
    xs: 3,
    s: 5,
    m: 10,
    l: 15,
    xl: 30,
    xxl: 100,
  },
  radius: {
    s: 2,
    m: 4,
    l: 6,
  },
  getFontValue,
};

export type ThemeConfig = typeof themeConfig;

export const getThemeValue = (path: string) => ({ theme }: any): any => {
  let currentValue = theme || themeConfig;

  path.split('.').forEach((val) => {
    currentValue = currentValue[val];
  });

  return currentValue;
};
