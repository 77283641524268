import React from 'react';
import { ViewContainer, Heading } from '../components';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '../stores/StoreContext';
import {
  PageTitle,
  SubHeading,
  RightJustifiedHeading,
} from '../components/Typography';
import { api } from '../services/api';
import { Bifrost } from '../decls/bifrost';
import { RollStatisticsTable } from '../components/RollStatisticsTable';
import { LootTable } from '../components/LootTable';
import { DistributedLoot } from '../models/DistributedLoot';
import { RollStats } from '../models/RollStats';
import { ClassIcon } from '../components/ClassIcon';

type CharacterViewProps = {};

const sortByDate = (a: DistributedLoot, b: DistributedLoot) =>
  a.dropped > b.dropped ? -1 : 1;

export const CharacterView: React.FC<CharacterViewProps> = observer(() => {
  const [attendance, setAttendance] = React.useState<{
    attended: number;
    total: number;
  }>();
  const [rollStats, setRollStats] = React.useState<RollStats>({});
  const [significantItems, setSignificantItems] = React.useState<
    DistributedLoot[]
  >([]);
  const [insignificantItems, setInsignificantItems] = React.useState<
    DistributedLoot[]
  >([]);
  const { characterId } = useParams();
  const { memberStore, blizzardStore } = useStores();
  const { getMemberByCharacterId } = memberStore;
  const member = getMemberByCharacterId(Number(characterId));

  React.useEffect(() => {
    async function fetchCharacterLoot() {
      if (!member) {
        return;
      }

      const response = await api.get<Bifrost.API.CharacterResponseData>(
        `/guilds/member/${member.id}/stats`,
        {
          baseURL: process.env.REACT_APP_API_URL?.replace('/api/v1', ''),
        }
      );

      if (response.status === 200) {
        setRollStats(response.data.stats);
        setAttendance(response.data.attendance);
        setSignificantItems(response.data.significant_drops.sort(sortByDate));
        setInsignificantItems(
          response.data.insignificant_drops.sort(sortByDate)
        );
      }
    }

    fetchCharacterLoot();
  }, [member]);

  if (!member) {
    return null;
  }

  return (
    <ViewContainer>
      <PageTitle>
        {member.character.name}
        <ClassIcon classy={member.character.classy} />
        <SubHeading>{member.rank}</SubHeading>
        {(attendance?.total || 0) > 0 && (
          <RightJustifiedHeading>
            {attendance?.attended || 0}/{attendance?.total || 0} Blackwing Lair
            attendance
          </RightJustifiedHeading>
        )}
      </PageTitle>
      <Heading>Statistics</Heading>
      <RollStatisticsTable stats={rollStats} zones={blizzardStore.zones} />
      <Heading>Significant drops</Heading>
      <LootTable items={significantItems} />
      <Heading>Other drops</Heading>
      <LootTable items={insignificantItems} />
    </ViewContainer>
  );
});
