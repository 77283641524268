import React from 'react';
import { ViewContainer } from '../components';
import { PageTitle, Heading } from '../components/Typography';
import { useStores } from '../stores/StoreContext';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { api } from '../services/api';
import { DistributedLoot } from '../models/DistributedLoot';
import { WowheadLink } from '../components/Link';
import { LootHistoryTable } from '../components/LootHistoryTable';

type ItemViewProps = {};

export const ItemView: React.FC<ItemViewProps> = observer(() => {
  const [drops, setDrops] = React.useState<DistributedLoot[]>([]);
  const { itemId } = useParams();
  const { blizzardStore } = useStores();
  const { getLootById } = blizzardStore;
  const item = getLootById(Number(itemId));

  React.useEffect(() => {
    async function fetchLootDetails() {
      const response = await api.get<DistributedLoot[]>(
        `/blizzard/items/${itemId}/drops`
      );

      if (response.status === 200) {
        setDrops(response.data);
      }
    }

    fetchLootDetails();
  }, [itemId]);

  if (!item) {
    return null;
  }

  return (
    <ViewContainer>
      <PageTitle>
        <WowheadLink to={item?.itemlink}>{item?.name}</WowheadLink>
      </PageTitle>
      <Heading>History</Heading>
      <LootHistoryTable items={drops} />
    </ViewContainer>
  );
});
