import '@reach/tooltip/styles.css';
import 'mobx-react/batchingForReactDom';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { App } from './App';
import { themeConfig, getFontValue } from './config/theme';
import { configure as configureMobx } from 'mobx';
import { StoreContext, StoreContextValue } from './stores/StoreContext';
import { GuildStore } from './stores/GuildStore';
import { MemberStore } from './stores/MemberStore';
import { BlizzardStore } from './stores/BlizzardStore';
import { RaidStore } from './stores/RaidStore';

configureMobx({ enforceActions: 'observed' });

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0px;
    margin: 0px;
  }

  html {
    background: ${({ theme }) => theme.gradient.body};
    min-height: 100%;
  }

  body {
    ${getFontValue('m')};
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const stores: StoreContextValue = {
  guildStore: new GuildStore(),
  memberStore: new MemberStore(),
  blizzardStore: new BlizzardStore(),
  raidStore: new RaidStore(),
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <StoreContext.Provider value={stores}>
        <ThemeProvider theme={themeConfig}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </StoreContext.Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
