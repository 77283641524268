import React from 'react';
import styled from 'styled-components';
import { NavLink } from './Link';
import { getFontValue } from '../config/theme';
import BifrostLogo from '../assets/bifrost_knot_64.png';
import { Link } from 'react-router-dom';

const Container = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 60px;
    background: ${theme.gradient.nav};
    box-shadow: ${theme.shadow.nav};
`
);

const Logo = styled(Link)`
  margin-right: auto;
  margin-left: 40px;
  display: flex;
  align-items: center;
`;

const MenuItem = styled(NavLink)`
  width: 170px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.navItem};
  color: ${({ theme }) => theme.colors.text.nav};
  text-transform: uppercase;
  ${getFontValue('s', 800)};

  &.nav-link-active,
  &:hover {
    background-color: ${({ theme }) => theme.colors.bg.activeNavItem};
  }
`;

export const Navigation: React.FC = () => (
  <Container>
    <Logo to="/">
      <img src={BifrostLogo} style={{ height: 36 }} />
    </Logo>
    <MenuItem exact to="/" activeClassName="nav-link-active">
      Home
    </MenuItem>
    <MenuItem to="/raids" activeClassName="nav-link-active">
      Raids
    </MenuItem>
    <MenuItem to="/items" activeClassName="nav-link-active">
      Items
    </MenuItem>
  </Container>
);
