import React from 'react';
import { ViewContainer, Link } from '../components';
import { useStores } from '../stores/StoreContext';
import { Raid } from '../models/Raid';
import { observer } from 'mobx-react';
import { formatDistanceToNow, format } from 'date-fns';
import { PageTitle } from '../components/Typography';
import { GradientBox } from '../components/GradientBox';
import styled from 'styled-components';
import { getFontValue } from '../config/theme';
import { Tooltip } from '../components/Tooltip';
import { HeaderWithColumns } from '../components/HeaderWithColumns';
import { SortButton } from '../components/SortButton';

type RaidListViewProps = {};

const RaidBox = styled(GradientBox)`
  height: 60px;
  width: 100%;
  margin-bottom: 15px;
  border: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid #1c2325;
  }
`;

const RaidTitle = styled.h2`
  flex: 1;
  ${getFontValue('ml', 600)};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-left: 32px;
  align-self: center;
`;

const RaidDate = styled.h3`
  flex: 1;
  ${getFontValue('s', 600)};
  color: ${({ theme }) => theme.colors.text.tertiary};
  margin-right: 32px;
  align-self: center;
  text-align: right;
`;

const SingleRaid: React.FC<{ raid: Raid }> = observer(({ raid }) => {
  const { blizzardStore } = useStores();
  const { getZoneById } = blizzardStore;
  const zone = getZoneById(raid.zone);
  const raidDate = new Date(raid.raid_date);

  if (!zone) {
    return null;
  }

  return (
    <Link to={`/raids/${raid.id}`}>
      <RaidBox>
        <RaidTitle>{zone.name}</RaidTitle>
        <RaidDate>
          <Tooltip label={format(raidDate, 'yyyy-MM-dd')}>
            <span>{formatDistanceToNow(raidDate)} ago</span>
          </Tooltip>
        </RaidDate>
      </RaidBox>
    </Link>
  );
});

const sortByRaidDateDescending = (a: Raid, b: Raid) =>
  a.raid_date > b.raid_date ? -1 : 1;

const sortByRaidDateAscending = (a: Raid, b: Raid) =>
  a.raid_date > b.raid_date ? 1 : -1;

export const RaidListView: React.FC<RaidListViewProps> = observer(() => {
  const [descending, setDescending] = React.useState<boolean>(true);
  const { raidStore, guildStore } = useStores();
  const { getRaidsByGuild } = raidStore;
  const guild = guildStore.getActiveGuild;
  const raids = guild ? getRaidsByGuild(guild) : [];

  const changeSortDirection = (): void => {
    setDescending((desc) => !desc);
  };

  const sortMethod = descending
    ? sortByRaidDateDescending
    : sortByRaidDateAscending;

  return (
    <ViewContainer>
      <HeaderWithColumns>
        <PageTitle>Raids</PageTitle>
        <SortButton
          size={30}
          descending={descending}
          onClick={changeSortDirection}
          style={{ marginTop: 10 }}
        />
      </HeaderWithColumns>
      {raids.sort(sortMethod).map((raid) => (
        <SingleRaid key={raid.id} raid={raid} />
      ))}
    </ViewContainer>
  );
});
