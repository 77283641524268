import React from 'react';
import { ViewContainer, Heading } from '../components';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '../stores/StoreContext';
import { PageTitle, SubHeading } from '../components/Typography';
import { api } from '../services/api';
import { Character } from '../models/Character';
import { formatDistanceToNow } from 'date-fns';
import { CharacterList } from '../components/CharacterList';
import { RaidLootList } from '../components/RaidLootList';
import { DistributedLootWithDetails } from '../models/DistributedLoot';
import { Tooltip } from '../components/Tooltip';

type RaidViewProps = {};

export const RaidView: React.FC<RaidViewProps> = observer(() => {
  const { raidId } = useParams();
  const { raidStore, blizzardStore } = useStores();
  const [loot, setLoot] = React.useState<DistributedLootWithDetails[]>([]);
  const [attendees, setAttendees] = React.useState<Character[]>([]);
  const { getRaidById } = raidStore;
  const { getZoneById } = blizzardStore;
  const raid = getRaidById(Number(raidId));
  const zone = raid ? getZoneById(raid.zone) : null;

  React.useEffect(() => {
    async function fetchLoot() {
      const response = await api.get<DistributedLootWithDetails[]>(
        `/raid/raids/${raidId}/drops`
      );

      if (response.status === 200) {
        setLoot(response.data);
      }
    }

    async function fetchAttendees() {
      const response = await api.get<Character[]>(
        `/raid/raids/${raidId}/attendees`
      );

      if (response.status === 200) {
        setAttendees(response.data);
      }
    }

    fetchAttendees();
    fetchLoot();
  }, [raidId]);

  if (!raid || !zone) {
    return null;
  }

  const raidDate = new Date(raid.raid_date);

  return (
    <ViewContainer>
      <PageTitle>
        {zone.name}
        <SubHeading uppercase={false} marginLeft={20}>
          <Tooltip label={raid.raid_date}>
            <span>{formatDistanceToNow(raidDate)} ago</span>
          </Tooltip>
        </SubHeading>
      </PageTitle>
      {attendees.length > 9 && (
        <>
          <Heading>
            Attendees
            <SubHeading>{attendees.length}</SubHeading>
          </Heading>
          <CharacterList characters={attendees} />
        </>
      )}
      <RaidLootList loot={loot} />
    </ViewContainer>
  );
});
